import React from "react";
import { navigate } from "gatsby";
import { isLoggedIn } from "../services/auth";
import { useEffect } from "react";
import Header from "../components/header";
import Footer1 from "../components/footer-1";
import PaymentOptions from "../components/check-out/payment-options";
import OrderSummaryTab from "../components/check-out/order-summary-tab";
import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useApplicationContext } from "../../provider";
import CheckoutForm from "../components/credit-checkout/checkout-form-cart";
import { useLocation } from "@reach/router";
import { useDispatch, useSelector } from 'react-redux';
import { createPaymentIntent, selectPaymentState } from "../sagas/payment/paymentSlice";
import Swal from 'sweetalert2';

export default function Layout() {
  const dispatch = useDispatch();
  const { isCreatingPaymentIntentSuccess, isCreatingPaymentIntentFailed, CreatePaymentIntentResponse } = useSelector(selectPaymentState);

  const [ordercost, setOrderCost] = useState({});
  const [customCosts, setCustomCosts] = useState([]);
  const [stripePubkey, setStripePubkey] = useState();
  const [clientSecret, setClientSecret] = useState("");
  const [options, setOptions] = useState({});
  const { setCreditCount, checkoutState } = useApplicationContext();
  const [open, setOpen] = useState(false);
  const [paymentIntentId, setPaymentIntentId] = useState();
  const [carouselImgArr, setCarouselImgArr] = React.useState([]);
  let finalItems =
    typeof window !== "undefined" &&
    JSON.parse(localStorage.getItem("final-products") || "[]");

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const payment_intent_client_secret = query.get('payment_intent_client_secret');

  const getOrderEst_response = typeof window !== "undefined" && JSON.parse(localStorage.getItem("getOrderEst_response") || "{}");
  
  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/signin");
    }

    if (finalItems.length > 0 && getOrderEst_response.data) {
      LoadData();
      getAllImages();
    } else {
      navigate("/beta");
    }
  }, []);

  const LoadData = () => {
    localStorage.setItem(
      "order-cost",
      JSON.stringify(getOrderEst_response.data.custom_calculations)
    );

    localStorage.setItem(
      "order-cost-items",
      JSON.stringify(getOrderEst_response.data.custom_calculations_items)
    );

    const grand_total = getOrderEst_response.data.custom_calculations.grand_total;
    setOrderCost(getOrderEst_response.data.custom_calculations);
    setCustomCosts(getOrderEst_response.data.custom_calculations_items);    

    getPaymentId(grand_total);
  };

  const [amount, setAmount] = useState();
  const getPaymentId = async (amount) => {
    setAmount(amount);

    const getPaymentId_response =
      typeof window !== "undefined" && JSON.parse(localStorage.getItem("getPaymentId_response") || "{}");
    
    if (getPaymentId_response.key && payment_intent_client_secret) {
      setClientSecret(getPaymentId_response.paymentIntent.client_secret);
      setOptions({
        clientSecret: getPaymentId_response.paymentIntent.client_secret,
        appearance: {},
      });
      setPaymentIntentId(getPaymentId_response.paymentIntent.id);
      setStripePubkey(getPaymentId_response.key);
    } else {
      const refined_amount = parseInt(amount.replace('.', ''));

      dispatch({
        type: createPaymentIntent.type,
        payload: { currency: "usd", amount: refined_amount, type: 'order' },
      });
    }
  };

  useEffect(() => {
    if (isCreatingPaymentIntentSuccess) {
      localStorage.setItem("getPaymentId_response", JSON.stringify(CreatePaymentIntentResponse));

      setClientSecret(CreatePaymentIntentResponse.paymentIntent.client_secret);
      setOptions({
        clientSecret: CreatePaymentIntentResponse.paymentIntent.client_secret,
        appearance: {},
      });
      setPaymentIntentId(CreatePaymentIntentResponse.paymentIntent.id);
      setStripePubkey(CreatePaymentIntentResponse.key);
    } else if (isCreatingPaymentIntentFailed) {
      Swal.fire({
        icon: 'error',
        text: 'Unable to create a payment intent',
        confirmButtonText: 'Retry',
      }).then(async(result) => {
        if (result.isConfirmed) getPaymentId(amount);
      })
    }
  }, [isCreatingPaymentIntentSuccess, isCreatingPaymentIntentFailed, CreatePaymentIntentResponse])

  const helperFunctionToGetProductImg = (key, thumbnail) => {
    try {
      const allItems = JSON.parse(localStorage.getItem("allItems") || "[]");
      const item = allItems.find((item) => item.key === key);

      return item.value.images[0];
    } catch (error) {
      return thumbnail;
    }
  }

  const getAllImages = () => {
    let newArr = [];

    finalItems?.forEach((item) => {
      newArr.push({
        id: item.id, 
        image: helperFunctionToGetProductImg(item.unique_type, item.files[0].url), 
        price: item.retail_price, 
        model: item.model,
        size: item.size,
        color: item.color,
      });
    });

    setCarouselImgArr(newArr);

    localStorage.setItem(
      "order-carousel-images",
      JSON.stringify(newArr)
    );
  };

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  const goToCheckout = async () => {
    const payload = {
      order: {
        recipient: checkoutState.recipientAddress,
        items: finalItems,
        external_id: new Date().getTime(),
        shipping: "STANDARD",
        gift: {
          subject: checkoutState.recipientAddress?.firstname,
          message: "Have a nice day",
        },
        packing_slip: {},
      },

      order_costs: ordercost,
      payment_intent: {
        currency: "usd",
        intentId: paymentIntentId,
        cost: ordercost.grand_total,
      },
    };

    localStorage.setItem("printfulOrder_payload", JSON.stringify(payload));

    setOpen(true);
  };

  const goToBack = () => {
    navigate('/shipping-address');
  }

  return (
    clientSecret !== "" &&
    stripePubkey && (
      <Elements stripe={loadStripe(stripePubkey)} options={options}>
        <div>
          <div className="container-main" id="page">
            <Header></Header>
            <main className="content-main">
              <div className="shipping-form">
                <div className="container">
                  <div className="row">
                    <PaymentOptions
                      goToCheckout={goToCheckout}
                      goToBack={goToBack}
                    ></PaymentOptions>
                    <OrderSummaryTab
                      summaryDetail={ordercost} customCosts={customCosts} ReviewOrder={carouselImgArr} 
                    ></OrderSummaryTab>
                  </div>
                </div>
              </div>
            </main>
            <Footer1></Footer1>
          </div>
        </div>
        <CheckoutForm
          open={open}
          handleClose={handleClose}
          setCreditCount={setCreditCount}
          setOpen={setOpen}
        />
      </Elements>
    )
  );
}
