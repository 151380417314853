import React, {useState, useEffect} from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { navigate } from "gatsby";
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { Box } from '@mui/system';
import { useLocation } from "@reach/router";
import Swal from 'sweetalert2';

export default function CheckoutForm({open, handleClose}){
  const stripe = useStripe();
  const elements = useElements();
  const hostUrl = window.location.protocol + '//' + window.location.host;

  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);
    const {error} = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${hostUrl}/payment-cart`,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
    setIsLoading(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{
          textAlign: 'center',
          color: 'black',
          backgroundColor: 'white'
        }}>
          {"Checkout"}
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: '#f6f5ff'
          }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            m: 'auto',
            width: '100%',
            textAlign: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
            paddingTop: '34px',
            fontSize:'20px'
          }}>
            <span>Payment:</span>
          </Box>
        </DialogContent>
        <Box sx={{
          color: 'white',
          backgroundColor: '#f6f5ff',
          padding: "16px 16px"
        }}>
          <form onSubmit={handleSubmit}>
            <PaymentElement />
            <div className='checkout-btn-container'>
              {isLoading ? <div className="spinner" id="spinner"></div> : <button className='checkout-btn btn' disabled={!stripe}>Submit</button>}
            </div>
            {/* Show error message to your customers */}
            {errorMessage && <div>{errorMessage}</div>}
          </form>
        </Box>
      </Dialog>

      <PaymentStatus />
    </>
  )
};

const PaymentStatus = () => {
  const stripe = useStripe();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  useEffect(() => {
    if (!stripe) {
      return;
    }
    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = query.get('payment_intent_client_secret');

    // Retrieve the PaymentIntent
    if(clientSecret) {
      stripe
        .retrievePaymentIntent(clientSecret)
        .then(({paymentIntent}) => {
          // Inspect the PaymentIntent `status` to indicate the status of the payment
          // to your customer.
          //
          // Some payment methods will [immediately succeed or fail][0] upon
          // confirmation, while others will first enter a `processing` state.
          //
          // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
          switch (paymentIntent.status) {
            case 'succeeded':
              Swal.fire({
                icon: 'success',
                text: 'Success! Payment received.',
                timer: 1500,
              }).then((result) => {
                navigate("/order-summary");
              });
              
              break;
  
            case 'processing':
              Swal.fire({
                icon: 'info',
                text: "Payment processing. We'll update you when payment is received.",
              });
              break;
  
            case 'requires_payment_method':
              // Redirect your user back to your payment page to attempt collecting
              // payment again
              Swal.fire({
                icon: 'error',
                text: "Payment failed. Please try another payment method.",
              });
              break;
  
            default:
              Swal.fire({
                icon: 'error',
                text: "Something went wrong. Please try again.",
              });
              break;
          }
        });
    }
  }, [stripe]);

  return (
    <>
    </>
  );
};
