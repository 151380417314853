import * as React from "react";

const orderSummaryTab = ({ summaryDetail, ReviewOrder, customCosts }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const getSize = (variant) => {
    if (!variant.color) return variant.size;
    return `${variant.color} Strap / ${variant.size}`;
  }

  return (
    <div className="col-lg-5 col-md-5">
      <div className="order-details">
        <div className="paymment-options" style={{ padding: "24px 20px" }}>
          <div className="review-order">
            <h3>{`Review Order (${ReviewOrder?.length})`}</h3>
            <ul>
              {ReviewOrder &&
                ReviewOrder.map((item, index) => (
                  <li key={index} style={{ justifyContent: "space-between" }}>
                    <div>
                      <div>
                        <img
                          style={{borderRadius:5}}
                          className="img-figure"
                          src={`${item.image}?version=90`}
                          alt="product-sml"
                        />
                      </div>
                    </div>
                    <div style={{ width:'47%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className="ml-1">
                      <h4 style={{ maxWidth: '150px', marginBottom: "5px", lineHeight: '16px' }}>{item.model}</h4>
                      <h4>Size: {getSize(item)}</h4>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                      <div className="price">{formatter.format(customCosts.find((cc) => cc.id === item.id)?.subtotal) ?? 0}</div>
                    </div>
                    {/* <div className="col-4">
                    <div className="qty">
                        <form action="#" method="post">
                        <div className="bgnumber">
                    <input name='qty' type='text' id='qty' className="number" value="1" />
                    </div>
                        <div className="plusminus">
                    <input type='button' name='add' onclick='javascript: document.getElementById("qty").value++;' className="increment"/>
                    <input type='button' name='subtract' onclick='javascript: subtractQty();' className="decrement"/>
                    </div>
                        </form>
                    </div>
                    </div> */}
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>

      <div className="order-summary mt-3">
        <h3>Order Summary</h3>
        <div className="aside">
          <ul>
            <li>
              <span className="left-l">Subtotal</span>{" "}
              <span className="right-r">{summaryDetail?.subtotal ? formatter.format(summaryDetail?.subtotal) : ''}</span>
            </li>
            <li>
              <span className="left-l">Shipping</span>{" "}
              <span className="right-r">{summaryDetail?.shipping ? formatter.format(summaryDetail?.shipping) : ''}</span>
            </li>
            <li>
              <span className="left-l">Sales Tax</span>{" "}
              <span className="right-r">{summaryDetail?.sales_tax ? formatter.format(summaryDetail?.sales_tax) : ''}</span>
            </li>
            <li>
              <span className="left-l">Merchant Fee</span>{" "}
              <span className="right-r">{summaryDetail?.merchant_fees ? formatter.format(summaryDetail?.merchant_fees) : ''}</span>
            </li>
            <li>
              <span className="left-l">Processing Fee</span>{" "}
              <span className="right-r">{summaryDetail?.prcessing_fees ? formatter.format(summaryDetail?.prcessing_fees) : ''}</span>
            </li>
          </ul>
          <div className="total">
            <span className="left-l">Grand Total</span>{" "}
            <span className="right-r">{summaryDetail?.grand_total ? formatter.format(summaryDetail?.grand_total) : ''}</span>
          </div>
        </div>
        <div className="gray-box">
          Our content review team usually accepts orders between 9am-6pm PST Monday-Saturday. Once your order is accepted, you'll receive a confirmation from us.
        </div>
      </div>
    </div>
  );
};

export default orderSummaryTab;
