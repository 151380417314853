import * as React from "react";
import { useApplicationContext } from "../../../provider";

export default function PaymentOptions({ goToCheckout, goToBack }) {
  const { checkoutState } = useApplicationContext();
  const recipientAddress = checkoutState?.recipientAddress ?? {};

  const [state, setState] = React.useState({
    cardnumber: "",
    expirationdate: "",
    cvv: "",
  });

  const onChange = (e) => {
    e.preventDefault();
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <div className="col-lg-7 col-md-6">
      <div className="order-details">
        <div className="paymment-options">
          <div className="steps">
            <ul>
              <li>Dot</li>
              <li className="active">Dot</li>
              <li>Dot</li>
            </ul>
          </div>
          <div className="form-field">
            <h3>Shipping Address</h3>
            <form action="#" method="post">
              <ul>
                <li className="sml-cl">
                  <input
                    disabled
                    id="name"
                    name="firstname"
                    type="text"
                    placeholder="Name"
                    onChange={onChange}
                    value={recipientAddress.firstname}
                  />
                </li>
                <li className="sml-cl">
                  <input
                    disabled
                    id="name"
                    name="lastname"
                    type="text"
                    placeholder="Name"
                    onChange={onChange}
                    value={recipientAddress.lastname}
                  />
                </li>
                <li>
                  <input
                    disabled
                    id="addressline1"
                    name="address1"
                    type="text"
                    placeholder="Address Line 1"
                    onChange={onChange}
                    value={recipientAddress.address1}
                  />
                </li>
                <li>
                  <input
                    disabled
                    id="addressline2"
                    name="address2"
                    type="text"
                    placeholder="Address Line 2"
                    onChange={onChange}
                    value={recipientAddress.address2}
                  />
                </li>
                <li className="sml-cl">
                  <input
                    id="city"
                    disabled
                    name="city"
                    type="text"
                    placeholder="City"
                    onChange={onChange}
                    value={recipientAddress.city}
                  />
                </li>
                <li className="sml-cl">
                  <input
                    id="state"
                    disabled
                    name="state"
                    type="text"
                    placeholder="State"
                    onChange={onChange}
                    value={recipientAddress.state_name}
                  />
                </li>
                <li className="sml-cl">
                  <input
                    id="country"
                    disabled
                    name="country"
                    type="text"
                    placeholder="Country"
                    onChange={onChange}
                    value={recipientAddress.country_name}
                  />
                </li>
                <li className="sml-cl">
                  <input
                    id="zip"
                    disabled
                    name="zip"
                    type="text"
                    placeholder="Zip Code"
                    // onChange={onChange}
                    value={recipientAddress.zip}
                  />
                </li>
               
              </ul>
            </form>
          </div>
        </div>

        <div className="bottom-btns">
          <a href="#paymment" className="btn" data-bs-toggle="modal" onClick={goToBack}>
            Back
          </a>
          <a
            href="#"
            onClick={(e)=>goToCheckout(e)}
            className="btn btn-blue"
          >
            Continue
          </a>
        </div>
      </div>
    </div>
  );
}
